
import PageNavigation from "@/components/header/PageNavigation";

export default {
    name: 'MobileMenu',

    components: {
        PageNavigation,
    },
    props: {
        selectedLanguage: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            isShowedMenu: false,
        }
    },

    computed: {
        isLoggedIn() {
            return !!this.$store.state.account.user
        },
    },

    methods: {
        handleClickMenuIcon() {

        }
    }
}
