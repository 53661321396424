
import Alert from "@/components/Alert";
import SiteHeader from "@/components/SiteHeader";
import firebaseApp from "@/firebaseApp";
import { authStateHandler, backendExceptionHandler } from "@/mixins";
import AuthService from '@/service/AuthService';
import PortfolioService from "@/service/PortfolioService";
import routingUtils from "@/utils/routing.utils";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { THEME_MODE_OPTIONS } from '../constants/theme.constants';

const { directToTranslatedRoute } = routingUtils
const auth = getAuth(firebaseApp);

const PING_INTERVAL_MS = 5000  // milliseconds

export default {
    name: 'App',
    components: { Alert, SiteHeader },
    mixins: [backendExceptionHandler, authStateHandler],
    data() {
        return {
            errorMessage: null,
            portfolios: [],
            overlay: false,
            networkError: false,
            componentKey: true,
            authInitialized: false || process.server,
            settingThemeMode: THEME_MODE_OPTIONS.light
        }
    },
    computed: {
        isLoggedIn() {
            return this.$store.state.account.user
        },
        themeMode() {
            return this.$store.state.theme.themeMode
        },
    },
    watch: {
        isLoggedIn(user) {
            if (!user) {
                return
            }
            this.fetchAccountCurrency()
        },
    },
    created() {
        this.portfolioService = new PortfolioService(this.$axios)
        this.unsubscribe = onAuthStateChanged(auth, this.authStateChanged)
        if (!this.$store.state.account.user?.preventLogout) {
            // workaround for e2e testing until we use Firebase emulator
            this.$store.commit('account/logout')
        }

    },
    beforeMount() {
        this.$root.$on('network-error', this.ping)
        this.$root.$on('account-currency-changed', this.accountCurrencyChanged);
    },
    beforeDestroy() {
        clearTimeout(this.timeout)
        this.unsubscribe()
        this.$root.$off('network-error', this.ping)
        this.$root.$off('account-currency-changed', this.accountCurrencyChanged)
    },
    methods: {
        cancelAutoUpdate() {
            clearInterval(this.timer);
        },

        async ping(retry = false) {
            if (this.networkError && !retry) {
                return  // we are already monitoring the connection
            }
            this.networkError = true
            this.overlay = true
            try {
                await AuthService.ping(this.$axios, this.$t)
                this.networkError = false
                this.timeout = setTimeout(() => this.overlay = false, 2000)
            } catch (e) {
                this.timeout = setTimeout(this.ping, PING_INTERVAL_MS, true)
            }
        },
        fetchAccountCurrency() {
            this.portfolioService.getAccount().then(account => {
                this.$store.commit(
                    'account/setSubscribedProductIds',
                    account.subscribedProductIds)
                this.$store.commit(
                    'account/setIsSubscriber', account.isSubscriber)
                this.$store.commit('account/setCurrency', account.currency)
                this.$store.commit('account/setShowNewUsersTour', account.showNewUsersTour)
                this.$store.commit('account/setCustomIcon', account.customIcon)

                let routeLang = this.$route.path.split('/')?.[1];
                routeLang = this.$getAvailableLocaleCodes().includes(routeLang) ?
                    routeLang :
                    null;

                const isFromRootPage = this.$getAvailableLocaleCodes().includes(this.$nuxt.context.from?.name);
                const accountLang = account.language;
                // After login, users are redirected to the language version specified in their account settings.
                if (isFromRootPage) {
                    this.setAccountLanguage(account)
                    if (!routeLang) {
                        this.$router.replace({ name: this.$getTranslatedRouteName('finance-dashboard') })
                    } else if (routeLang !== accountLang) {
                        directToTranslatedRoute({
                            route: this.$route,
                            router: this.$router,
                            currentLocale: routeLang,
                            newLocale: accountLang
                        })
                    }
                } else {
                    // The language code in the URL takes precedence over any language settings in the user’s account.
                    if (!routeLang) {
                        this.setAccountLanguage(account)
                        this.$router.replace({ name: this.$getTranslatedRouteName('finance-dashboard') })
                    } else {
                        this.$setLocale(routeLang);
                        this.$vuetify.lang.current = routeLang;
                        if (routeLang !== accountLang) {
                            AuthService.updateUserLanguage(routeLang, this.$axios, this.$t);
                        }
                    }
                }
            }).catch(e => this.handleBackendException(
                e, this.$t('layouts.default.accountCurrencyFetchError'))
            )
        },
        setAccountLanguage(account) {
            const locale = account.language;
            this.$vuetify.lang.current = locale;
            this.$setLocale(locale);
        },
        accountCurrencyChanged(newCurrency) {
            this.$store.commit('account/setCurrency', newCurrency)
        },
        updateErrorMessage() {
            this.errorMessage = '';
        }
    },
};
