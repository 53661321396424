import { render, staticRenderFns } from "./Searchbar.vue?vue&type=template&id=6e468c08&scoped=true&"
import script from "./Searchbar.vue?vue&type=script&lang=js&"
export * from "./Searchbar.vue?vue&type=script&lang=js&"
import style0 from "./Searchbar.vue?vue&type=style&index=0&id=6e468c08&prod&lang=sass&scoped=true&"
import style1 from "./Searchbar.vue?vue&type=style&index=1&id=6e468c08&prod&lang=sass&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e468c08",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Alert: require('/builds/spatz/ui/web/spatz-app/components/Alert.vue').default})
