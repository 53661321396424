import de from '../locales/de.json'
import en from '../locales/en.json'
import es from '../locales/es.json'
import fr from '../locales/fr.json'

const FALLBACK_LOCALE = 'de';

const AVAILABLE_LOCALES = [
    { code: 'de', name: 'Deutsch', flag: 'de.png', translations: de, isBeta: false },
    { code: 'en', name: 'English', flag: 'en.png', translations: en, isBeta: false },
    { code: 'es', name: 'Spanish', flag: 'es.png', translations: es, isBeta: true },
    { code: 'fr', name: 'French', flag: 'fr.png', translations: fr, isBeta: true }
];

const AVAILABLE_LOCALE_CODES = AVAILABLE_LOCALES.map(l => l.code);

export default {
    FALLBACK_LOCALE,
    AVAILABLE_LOCALES,
    AVAILABLE_LOCALE_CODES
}
