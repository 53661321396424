import Vue from 'vue';
import routingUtils from '~/utils/routing.utils';
import localizationConfigs from '~/config/localization.config';
import localizationUtils from '~/utils/localization.utils';

const {
    getTranslatedRouteName,
    getTranslatedRoutePath,
    getTranslatedTabId,
    getOriginalTabId,
    getOriginalRoutePath,
} = routingUtils;
const { FALLBACK_LOCALE, AVAILABLE_LOCALES, AVAILABLE_LOCALE_CODES } = localizationConfigs;
const { detectBrowserLanguage } = localizationUtils

const getTranslationsForLocale = (locale) => {
    const localeData = AVAILABLE_LOCALES.find(l => l.code === locale);
    return localeData ? localeData.translations : null;
};

const getAvailableLocales = () => {
    return AVAILABLE_LOCALES;
};

const getAvailableLocaleCodes = () => {
    return AVAILABLE_LOCALE_CODES;
};

const getFallbackLocale = () => {
    return FALLBACK_LOCALE;
};

const setLocale = (locale, store) => {
    const localeCode = AVAILABLE_LOCALES.map(l => l.code).includes(locale) ? locale : FALLBACK_LOCALE;
    store.commit('preferences/setLocale', localeCode);
};

const getCurrentLocale = (store, route) => {
    const isLoggedIn = !!store.state.account.user
    const routeLang = route.path.split('/')?.[1];
    const shouldUseRouteLang = !isLoggedIn && AVAILABLE_LOCALE_CODES.includes(routeLang)
    const language = shouldUseRouteLang ?
        routeLang :
        (store.getters['preferences/getLocale'] || detectBrowserLanguage());
    return AVAILABLE_LOCALE_CODES.includes(language) ? language : FALLBACK_LOCALE;
};

const translate = (key, options = {}, locale, store, route) => {
    const currentLocale = locale || getCurrentLocale(store, route);
    const translations = getTranslationsForLocale(currentLocale);  // Get translations dynamically

    if (!translations || !key || typeof key !== 'string') {
        return key;
    }

    let translation = translations[key];

    if (!translation) {
        return key;
    }

    translation = translation.replace(/{(\w+)}/g, (match, p1) => {
        return options[p1] !== undefined ? options[p1] : match;
    });

    return translation;
};

export default (context) => {
    Vue.prototype.$t = (key, options = {}, locale) => translate(key, options, locale, context.store, context.route);
    Vue.prototype.$setLocale = (locale) => setLocale(locale, context.store);
    Vue.prototype.$detectBrowserLanguage = detectBrowserLanguage;
    Vue.prototype.$getCurrentLocale = () => getCurrentLocale(context.store, context.route);
    Vue.prototype.$getFallbackLocale = getFallbackLocale;
    Vue.prototype.$getAvailableLocales = getAvailableLocales;
    Vue.prototype.$getAvailableLocaleCodes = getAvailableLocaleCodes;
    Vue.prototype.$getTranslatedRouteName = (originalName, locale) =>
        getTranslatedRouteName(originalName, locale ?? getCurrentLocale(context.store, context.route));
    Vue.prototype.$getTranslatedRoutePath = (originalPath, locale) =>
        getTranslatedRoutePath(originalPath, locale ?? getCurrentLocale(context.store, context.route));
    Vue.prototype.$getOriginalRoutePath = (path, locale) =>
        getOriginalRoutePath(path, locale ?? getCurrentLocale(context.store, context.route));
    Vue.prototype.$getTranslatedTabId = (originalTabId, locale) =>
        getTranslatedTabId(originalTabId, locale ?? getCurrentLocale(context.store, context.route));
    Vue.prototype.$getOriginalTabId = (tabId, locale) =>
        getOriginalTabId(tabId, locale ?? getCurrentLocale(context.store, context.route));
};
